import ConditionShow from "@/common/conditionShow";
import { ILoginForm } from "@/common/interface";
import Logo from "@/common/layout/header/img/logo.svg";
import useIntlDep from "@/hooks/useIntlDep";
import useSubmitEvent from "@/hooks/useSubmitEvent";
import useVisible from "@/hooks/useVisible";
import { ITokenStr } from "@/store/account/interface";
import accountService from "@/store/account/service";
import userAPI from "@/store/apis/account/user";
import reqServicePlx from "@/store/request/reqServicePlx";
import useWebSiteInfo from "@/store/webSiteInfo/useWebSiteInfo";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Spin, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { from } from "rxjs";
import "./index.less";
import useReCaptcha from "@/hooks/useReCaptcha";
const Login = () => {
  const [form] = Form.useForm();
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
  const [visible, changeVisible] = useVisible();
  const [loading, setLoading] = useState(false);
  // const IntlText = useIntlDep();
  const [notificationapi, contextHolder] = notification.useNotification();
  const IndText = useIntlDep();
  const webSiteInfo = useWebSiteInfo();
  const [logoadd, setLogoAdd] = useState<string>(Logo);
  useEffect(() => {
    if (webSiteInfo) {
      setLogoAdd(webSiteInfo.logoAddress);
    }
  }, [webSiteInfo]);
  // const recaptchaRef = useRef<ReCAPTCHA>(null);
  // useEffect(() => {
  //   const config = userAPI.findRecaptchaKey({}, {});
  //   reqAndRunCallback(config, (res) => {
  //     setRecaptchaKey(res);
  //   });
  // }, [setRecaptchaKey]);
  const onFinish = useCallback(
    async (data: any) => {
      if (!data.username || !data.password) {
        notification.warning({
          message: IndText({ id: "THE_USER_NAME_OR_PASSWORD_CANNOT_BE_EMPTY" }),
          duration: 1.5,
        });
        return;
      }
      if (loading) {
        return;
      }
      if (!reCaptchaLoaded) {
        notificationapi.warning({
          message: IndText({ id: "RECAPTCHA_NOT_LOADED" }),
          description: IndText({ id: "RECAPTCHA_LOADING_ERROR" }),
        });
        return;
      }
      const recaptchaResult = await generateReCaptchaToken("login");
      setLoading(true);
      const _data = {
        type: 1,
        ...data,
        recaptchaResult,
      };
      const config = userAPI.Login({}, _data);
      from(reqServicePlx(config)).subscribe((res) => {
        if (res.isSuccess && res.result) {
          setLoading(false);
          accountService.setToken(res.result as ITokenStr);
        } else if (!res.isSuccess) {
          setLoading(false);
          // if (recaptchaRef.current) {
          //   recaptchaRef.current.reset();
          // }
        }
      });
    },
    [loading, IndText, generateReCaptchaToken, reCaptchaLoaded, notificationapi]
  );

  const submitEvent = useCallback(() => {
    const data: ILoginForm = form.getFieldsValue();
    onFinish(data);
  }, [form, onFinish]);
  const submitClick = useSubmitEvent(submitEvent);
  return (
    <section className="login-page-form">
      {contextHolder}
      <div className="login-logo">
        <img src={logoadd} alt="logo" />
      </div>

      <ConditionShow className="login-loading-container" visible={loading}>
        <div className="login-loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <span>Waiting for login...</span>
        </div>
      </ConditionShow>
      <Form
        layout="vertical"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
      >
        <Form.Item label="Username" name="username">
          <Input />
        </Form.Item>
        <div className="zdy-password">
          <Form.Item label="Password" name="password">
            <Input
              type={visible ? "text" : "password"}
              onPressEnter={submitClick}
              className="password"
            />
          </Form.Item>
          <span onClick={changeVisible} className="zdy-password-icon">
            {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </span>
        </div>
        {/* {RecaptchaKey && (
          <Form.Item name="recaptchaResult">
            <ReCAPTCHA
              ref={recaptchaRef}
              grecaptcha={grecaptcha}
              sitekey={RecaptchaKey}
            />
          </Form.Item>
        )} */}
        <Button type="primary" className="btn-primary" onClick={submitClick}>
          {/* {IndText({ id: "LOG_IN" })} */}
          Log in
        </Button>
      </Form>
    </section>
  );
};

export default Login;
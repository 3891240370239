import { notification } from "antd";
import { AxiosResponse } from "axios";
import accountService from "../account/service";
import tool from "./tool";
import { IResponseBody } from "./interface";
import { languagePkg } from "@/locale";
let flag = false;
const log = tool.createDebug(true, "网络请求解析模块");
const noNeedAuthList: Array<string> = ["/login", "/login-plus"];
const pathname = window.location.pathname;
function resolveReq<T>(rep: AxiosResponse<IResponseBody<T>>) {
  const res = {
    data: null as any,
    success: false,
  };
  if (rep.data.code === 1000) {
    if (!flag && !noNeedAuthList.includes(pathname)) {
      notification.error({
        message:
          languagePkg &&
          languagePkg["LOGIN_HAS_EXPIRED_PLEASE_LOG_OUT_AND_LOG_IN_AGAIN"],
      });
    }
    flag = true;
    setTimeout(() => {
      flag = false;
      accountService.logout();
    }, 2000);
    return res;
  }
  if (rep.status !== 200) {
    log("网络请求失败");
    notification.error({
      message: rep.status,
      description: rep.data.msg || "-",
    });
    return res;
  }
  // 请求成功
  if (rep.data) {
    if (rep.data.msg === "success") {
      res.success = true;
      res.data = rep.data.data;
    } else {
      rep.data.msg &&
        notification.error({
          message: rep.data.msg,
        });
    }
  } else {
    notification.error({
      message: "网络请求错误",
      description: "-",
    });
  }
  if (!res.success) {
    // 请求失败
    log("请求异常，返回值如下\n", rep.data);
  }
  return res;
}

export default resolveReq;
